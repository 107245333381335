const { openFormModal, closeFormModal, closeOutsideFormModal } = require('./../services/formModal')
const { toggleMenuMobile, stickyHeader } = require('./../services/navbar')
const { emailjsInit, emailjsSendContactForm } = require('./../emailjs/index.js')

// Contact form
emailjsInit()
emailjsSendContactForm()

// Mobile menu
document.getElementById('menu-toggler').addEventListener('click', toggleMenuMobile)

// Sticky header
window.addEventListener('scroll', stickyHeader)
window.addEventListener('scroll', () => {
  document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
})

// Modal contact form (1. Open, 2. Close, 3. Close from click outside)
const contactButton = document.querySelectorAll('.contact-us-button-hover, .contact-us-button')
const closeModalButton = document.querySelector('.close-modal img')
const backToPageButton = document.querySelector('#back-to-page')
const contactUsFormContainer = document.getElementById('popup-contact-us-form-container')

contactButton.forEach(button => {
  button.addEventListener('click', openFormModal)
})

closeModalButton.addEventListener('click', closeFormModal)
backToPageButton.addEventListener('click', closeFormModal)

contactUsFormContainer.addEventListener('mouseup', closeOutsideFormModal)