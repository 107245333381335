let topScrollPosition = 0

function scrollHandler() {
  window.scrollTo({top: topScrollPosition, behavior: 'instant'})
}

function openFormModal() {
  const popupContactUsFormContainer = document.getElementById('popup-contact-us-form-container')

  popupContactUsFormContainer.classList.add('toggle-container-form')

  topScrollPosition = window.pageYOffset || document.documentElement.scrollTop
  document.addEventListener('scroll', scrollHandler)
}

function closeFormModal(event) {
  if (event) {
    event.preventDefault()
  }

  const popupContactUsFormContainer = document.getElementById('popup-contact-us-form-container')

  popupContactUsFormContainer.classList.remove('toggle-container-form')
  document.removeEventListener('scroll', scrollHandler)
}

function closeOutsideFormModal(event) {
  const popupContactUsForm = document.getElementById('popup-contact-form')

  if (popupContactUsForm.contains(event.target)) {
    return
  }
  closeFormModal()
}

module.exports = { openFormModal, closeFormModal, closeOutsideFormModal }