function emailjsInit() {
  emailjs.init(process.env.EMAILJS_PUBLIC_KEY)
}

function emailjsSendContactForm() {
  const serviceId = process.env.EMAILJS_SERVICE_ID
  const templateId = process.env.EMAILJS_TEMPLATE_ID
  const contactForm = document.querySelector('.contact-form-page')
  const contactFormModal = document.querySelector('.contact-form-modal')
  const modal = document.querySelector('#popup-contact-form')
  const thankYou = document.querySelector('.thank-you-message-page')
  const thankYouModal = document.querySelector('.thank-you-message-modal')

  const submitFormHandler = (event, thankYouMessage, thankYouMessageActive) => {
    event.preventDefault()
    event.target.classList.add('hidden');

    console.log('Form sending...')
    emailjs.sendForm(serviceId, templateId, event.target)
      .then(
        response => {
          console.log('Form sent.', response.status, response.text)
        },
        error => {
          console.log('Form not sent.', error)
        }
      )

    thankYouMessage.classList.add(thankYouMessageActive)
    event.target.reset()
  }
  // Normal form
  if (contactForm) {
    contactForm.addEventListener('submit', function (event) {
      submitFormHandler(event, thankYou, 'active');
    })
  }

  // Modal form
  contactFormModal.addEventListener('submit', function (event) {
    modal.classList.add('thank-you-active');
    submitFormHandler(event, thankYouModal, 'active');
  })
}

module.exports = { emailjsInit, emailjsSendContactForm }