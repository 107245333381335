const toggleMenuMobile = function() {
  document.getElementById('menu-toggler').classList.toggle('menu-active')
  document.getElementById('primary-menu').classList.toggle('menu-active')
}

const stickyHeader = function() {
  const header = document.querySelector('.navbar')

  const space = 1200
  const pageY = window.pageYOffset

  if (pageY > space) header.classList.add('sticky')

  else {
    header.classList.remove('sticky')
  }
}

module.exports = { toggleMenuMobile, stickyHeader }