const sliderImages = Array.from(document.querySelectorAll('.wrapper-steps-slides .options-selector-diplayer'))
const slidesCount = sliderImages.length

// Default/initializer
var currentSlide = 1

var nextButton = document.getElementById('next-steps')
var prevButton = document.getElementById('prev-steps')

// Handle next or previous slide
nextButton.onclick = nextStepSlide
prevButton.onclick = prevStepSlide

var stepItemButtons = Array.from(document.querySelectorAll('.data-switcher .step-item'))

for (var i = 0; i < stepItemButtons.length; i++) {

  stepItemButtons[i].onclick = function () {
    // Converts into int for switching correctly
    currentSlide = parseInt(this.getAttribute('data-index'))

    checker()
  }
}

checker()

function nextStepSlide() {
  if (nextButton.classList.contains('disabled')) return false

  else {
    // Update slide state value
    currentSlide++
    checker()
  }
}

// Previous slide function
function prevStepSlide() {
  if (prevButton.classList.contains('disabled')) return false

  else {
    // Update slide state value
    currentSlide--
    checker()
  }
}

function checker() {
  removeAllActive()

  sliderImages[currentSlide - 1].classList.add('active-display')
  stepItemButtons[currentSlide - 1].classList.add('active-step')

  //checks on previous
  if (currentSlide == 1) prevButton.classList.add('disabled')

  else prevButton.classList.remove('disabled')

  //checks on next
  if (currentSlide == slidesCount) {
    prevButton.classList.add('last-prev') // For dark color on last prev button
    nextButton.classList.add('disabled')
  }

  else {
    prevButton.classList.remove('last-prev')
    nextButton.classList.remove('disabled')
  }
}

function removeAllActive() {
  sliderImages.forEach(function (slide) {
    slide.classList.remove('active-display')
  })

  stepItemButtons.forEach(function (stepButton) {
    stepButton.classList.remove('active-step')
  })
}