const swiper = new Swiper('.swiper-container', {
  slidesPerView: 'auto',
  distanceBetween: 10,
  mousewheel: true,
  NavigationOptions: true,
  clickable: true,
  pagination: {
    el: '.swiper-pagination',
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  keyboard: true,
})
